import { APLHABET_CONFIGURATION, CONFIGURATION_ADDRESS, COUNTRY_CODES, ENTERPRISE_CONNECTION, FREQUENCY_TYPES, LANGUAGES, SECURITY_TYPES, URL_TYPES } from "./constants";
export const VALUES = {
    serverUrl: "", 
    networkSSID: "",
    networkPass: "",
    urlType: URL_TYPES.HTTP.value,
    frequencyBand: FREQUENCY_TYPES.DUAL_MODE.value,
    countryCode: COUNTRY_CODES.EU.value,
    deviceLanguage: LANGUAGES.English.value,
    securityType: SECURITY_TYPES.WPA_WPA2.value,
    timeOutForHttp: "20",
    networkConfigAddress: CONFIGURATION_ADDRESS.DHCP.value,
    rssiThreshold: "95",
    softRoamingHysteresis: "3",
    softRoamingRssiThreshold: "75",
    enterpriseConnParameter: ENTERPRISE_CONNECTION.TLS.value,
    //enterPriseCertificateDate: new Date(),
    telnetParameters: ["", "", "", "", ""],
    ipAddress: ["192", "168", "2", "1"],
    subNetMask: ["255", "255", "255", "0"],
    defaultGatewayAddress: ["192", "168", "1", "1"],
    dnsServerAddress: ["1", "1", "1", "1"],
    screenBacklight: "20",
    logPeriod: "120",
    keypadTimeOut: "50",
    keypadAlphabetConfiguration: APLHABET_CONFIGURATION.CONF_ONLY_UPPER_CASE.value,
    fn0: "\\0",
    fn1: "\\0",
    fn2: "\\0",
    fn3: "\\0",
    fn4: "\\0",
    fn5: "\\0",
    fn6: "\\0",
    fn7: "\\0",
    fn8: "\\0",
    fn9: "\\0",
    fnBSP: "\\0",
    fnUP: "\\0",
    fnOK: "\\0",
    fnDown: "\\0",
    down: "\\0",
    ok: "\\0",
    up: "\\0",
    bsp: "\\0",
    shortClick: "\\0",
    doubleClick: "\\0",
    scanEndOf: "\\0",
}