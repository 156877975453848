import axios from 'axios';
import React, { createContext, useState, useEffect } from 'react';
import { VALUES } from '../helpers/DefaultValues';

export const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {

    const [routerData, setRouterData] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [currentLanguageCode, setCurrentLanguageCode] = useState("en");
    const [formData, setFormData] = useState([]);
    const [activeStep, setActiveStep] = useState(1);
    const [projectStatus, setProjectStatus] = useState(null);
    const [breadcrumb, setBreadcrumb] = useState(null);
    const [productImages, setProductImages] = useState([]);

    const [configFormData, setConfigFormData] = useState({
        advancedMode: false,
        keyPadMode: false,
        useLogURL: false,
        ...VALUES
    });   
    // useEffect(() => {
    //     // get page definition
    //     axios.get("http://3.64.64.252:7012/api/dashboardpage")
    //     .then((response) => {
    //         setRouterData(response.data)
    //         setLoading(false)
    //     })
    //     .catch((err) => {
    //         setRouterData([])
    //         console.log(err);
    //         setLoading(false)
    //     })
    // }, [])
    const [sidebarShow, setSidebarShow] = useState(true);
    const [sidebarUnfoldable, setSidebarUnfoldable] = useState(false);
    return (
        <AppContext.Provider value={{
            currentLanguageCode, setCurrentLanguageCode,
            routerData, setRouterData,
            loading, setLoading,
            sidebarShow, setSidebarShow,
            sidebarUnfoldable, setSidebarUnfoldable,
            userInfo, setUserInfo,
            formData, setFormData,
            selectedProjectId, setSelectedProjectId,
            selectedProject, setSelectedProject,
            activeStep, setActiveStep,
            projectStatus, setProjectStatus,
            breadcrumb, setBreadcrumb,
            configFormData, setConfigFormData,
            productImages, setProductImages
        }}>
            {children}
        </AppContext.Provider>
    )
}